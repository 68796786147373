import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import img1 from '../../utils/img/1.webp';
import img2 from '../../utils/img/2.webp';
import img3 from '../../utils/img/3.webp';
import img4 from '../../utils/img/4.webp';

const Features = () => {
    const services = [
        {
            id: 1,
            src: img1,
            title: "Diseño y Desarrollo de Páginas Web",
            url: "URL_DEL_SERVICIO_1",
            content: "Creamos sitios web profesionales, modernos y optimizados para ofrecer una experiencia excepcional a tus usuarios. Ya sea que necesites una página informativa, una tienda en línea o un portafolio, desarrollamos soluciones adaptadas a tus objetivos.",
            Title1: "Optimización para SEO y Velocidad",
            Content1: "Garantizamos que tu sitio web cargue rápido y sea fácil de encontrar en buscadores como Google.",
            Title2: "Diseño Responsivo",
            Content2: "Tu página se verá perfecta en cualquier dispositivo, desde smartphones hasta computadoras.",
            Title3: "Interfaz Personalizada",
            Content3: "Diseños únicos que reflejan la identidad de tu marca.",
            Title4: "Optimización Continua",
            Content4: "Diseñamos sitios web listos para evolucionar, permitiendo futuras actualizaciones y expansiones sin complicaciones"
        },
        {
            id: 2,
            src: img2,
            title: "Desarrollo de Aplicaciones Web",
            url: "URL_DEL_SERVICIO_2",
            content: "Desarrollamos aplicaciones web personalizadas que simplifican procesos y mejoran la interacción con tus clientes. Desde herramientas de gestión empresarial hasta plataformas interactivas, nuestras soluciones están diseñadas para crecer con tu negocio.",
            Title1: "Soluciones a Medida",
            Content1: "Creamos aplicaciones adaptadas a las necesidades específicas de tu empresa.",
            Title2: "Escalabilidad Garantizada",
            Content2: "Diseñamos aplicaciones que crecen contigo, soportando más usuarios y funcionalidades con el tiempo.",
            Title3: "Seguridad Avanzada",
            Content3: "Integramos las mejores prácticas de seguridad para proteger tus datos y los de tus usuarios.",
            Title4: "Integración Sencilla",
            Content4: "Conectamos tu aplicación con otros sistemas y herramientas de manera eficiente."
        },
        {
            id: 3,
            src: img3,
            title: "Mantenimiento y Soporte Técnico",
            url: "URL_DEL_SERVICIO_4",
            content: "Nos aseguramos de que tu sitio web, aplicación o software funcionen de manera óptima en todo momento. Ofrecemos soporte técnico confiable y soluciones rápidas a cualquier problema.",
            Title1: "Monitoreo Proactivo",
            Content1: "Detectamos y solucionamos problemas antes de que afecten a tu negocio.",
            Title2: "Actualizaciones Regulares",
            Content2: "Mantén tus sistemas seguros y actualizados con las últimas tecnologías.",
            Title3: "Asistencia Personalizada",
            Content3: "Nuestro equipo está disponible para ayudarte con cualquier duda o inconveniente.",
            Title4: "Backup y Recuperación",
            Content4: "Protegemos tus datos con copias de seguridad y planes de recuperación ante fallos."
        },
        {
            id: 4,
            src: img4,
            title: "Consultoría Digital",
            url: "URL_DEL_SERVICIO_4",
            content: "Te ayudamos a definir una estrategia digital clara y efectiva para maximizar los resultados de tu presencia en línea. Nuestra experiencia garantiza soluciones prácticas y adaptadas a tu sector.",
            Title1: "Análisis Detallado",
            Content1: "Evaluamos tus necesidades y te damos un plan de acción claro.",
            Title2: "Optimización de Recursos",
            Content2: "Te ayudamos a aprovechar al máximo tus herramientas digitales.",
            Title3: "Innovación Constante",
            Content3: "Te mantenemos al día con las últimas tendencias y tecnologías.",
            Title4: "Resultados Medibles",
            Content4: "Cada recomendación está orientada a obtener resultados concretos."
        },
    ];

    const [selectedService, setSelectedService] = useState(null);

    useEffect(() => {
        if (selectedService) {
            // Evita el scroll del fondo
            document.body.style.overflow = "hidden";
        } else {
            // Restaura el scroll
            document.body.style.overflow = "";
        }

        // Limpia el efecto cuando se desmonta el componente
        return () => {
            document.body.style.overflow = "";
        };
    }, [selectedService]);
    // Cerrar el modal
    const closeModal = () => {
        setSelectedService(null);
    };

    return (
        <>
            <section className="features bg-white py-16 mt-2 overflow-x-hidden" id="que-podemos-hacer">
                <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start justify-between px-4">
                    {/* Sección de Títulos alineados a la izquierda (centrado en dispositivos móviles) */}
                    <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.7, ease: "easeOut" }}
                        viewport={{ once: true, amount: 0.5 }}
                        className="md:w-1/2 mb-8 md:mb-0 lg:mb-0 lg:mt-20 lg:ml-10"
                    >
                        <div className="text-center md:text-left mb-4 lg:text-left">
                            <span className="text-gray-700 text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
                                ¿Qué podemos hacer?
                            </span>
                        </div>

                        <h1 className="text-[#3456E6] text-3xl text-center sm:text-left md:text-5xl lg:text-5xl font-bold mb-6 leading-tight mx-auto sm:mx-0 lg:text-left lg:mb-8" id="services-heading">
                            Explora nuestros servicios
                        </h1>

                        <div className="text-gray-700 text-lg text-center md:text-left lg:text-left">
                            <p>
                                En <strong>TuBisnez</strong>, nos especializamos en soluciones digitales que impulsan el crecimiento de empresas en <strong>Pasto</strong> y <strong>Nariño</strong>.
                                Nuestros servicios incluyen diseño web, desarrollo de aplicaciones y consultoría digital.
                                <span className="italic">¡Trabajemos juntos para llevar tu negocio al siguiente nivel!</span>
                            </p>
                        </div>
                        <div className="mt-4 text-center md:text-left lg:text-left">
                            <motion.button
                                onClick={() => window.open("https://wa.me/573106014402?text=Hola,%20vengo%20por%20una%20consulta%20o%20me%20interesan%20sus%20servicios.", "_blank")}
                                whileHover={{ scale: 1.1 }}
                                className="bg-[#3456E6] text-white text-base sm:text-lg md:text-xl lg:text-1xl font-semibold py-2 sm:py-3 px-4 sm:px-6 rounded-full hover:bg-blue-700 transition duration-200 mx-auto sm:mx-0 lg:mx-0 lg:mt-6"
                            >
                                Descrubre como podemos ayudarte
                            </motion.button>
                        </div>
                    </motion.div>

                    {/* Grid de Servicios a la derecha */}
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-4 md:w-1/2 px-4">
                        {services.map((service, index) => (
                            <motion.div
                                key={service.id}
                                initial={{ opacity: 0, scale: 0.9 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                viewport={{ once: true, amount: 0.3 }}
                                className={`img-features mb-3 relative bg-black text-white rounded-lg overflow-hidden shadow-md cursor-pointer transition duration-300 transform hover:scale-105 hover:shadow-[0px_0px_20px_10px_rgba(52,86,230,0.7)]`}
                                onClick={() => setSelectedService(service)}
                            >
                                <img
                                    src={service.src}
                                    alt={`Imagen representativa del servicio ${service.title}`}
                                    className="w-full h-full object-cover"
                                    style={{ filter: "brightness(0.7)" }}
                                />
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="bg-black bg-opacity-30 w-full h-full flex items-end justify-center">
                                        <h3 className="font-semibold text-lg mb-2 mx-2" aria-label={`Servicio: ${service.title}`}>
                                            {service.title}
                                        </h3>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>

                {/* Modal */}
                <AnimatePresence>
                    {selectedService && (
                        <motion.div
                            itemScope
                            itemType="https://schema.org/Service"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto"
                        >
                            <motion.div
                                initial={{ scale: 0.9, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.9, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className="bg-white rounded-xl shadow-xl overflow-hidden w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-4xl relative"
                            >
                                {/* Botón de cierre */}
                                <button
                                    className="mt-10 md:mt-0 z-40 absolute top-4 right-4 text-gray-600 bg-gray-200 hover:bg-gray-300 rounded-full w-10 h-10 flex items-center justify-center lg:block sm:hidden"
                                    onClick={closeModal}
                                >
                                    ✕
                                </button>

                                <div className="flex flex-col lg:flex-row">
                                    {/* Imagen fija para pantallas grandes y responsiva para móviles */}
                                    <div className="flex-shrink-0 w-full lg:w-1/2 h-64 lg:h-auto">
                                        <img
                                            src={selectedService.src}
                                            alt={selectedService.title}
                                            className="w-full h-full object-cover"
                                            style={{ position: "sticky", top: 0 }}
                                        />
                                    </div>

                                    {/* Contenido con scroll */}
                                    <div className="p-8 lg:w-1/2 flex flex-col justify-between overflow-y-auto lg:max-h-screen max-h-[calc(100vh-200px)]">
                                        <div>
                                            <h2 className="text-3xl font-bold text-[#3456E6] mb-4" itemProp="name">
                                                {selectedService.title}
                                            </h2>
                                            <p className="text-gray-600 text-lg mb-6" itemProp="description">
                                                {selectedService.content}
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                                            <div className="bg-white p-4 rounded-lg shadow-lg">
                                                <h3 className="text-lg font-semibold text-[#3456E6]" itemProp="name">
                                                    {selectedService.Title1}
                                                </h3>
                                                <p className="text-gray-600" itemProp="description">
                                                    {selectedService.Content1}
                                                </p>
                                            </div>
                                            <div className="bg-white p-4 rounded-lg shadow-lg">
                                                <h3 className="text-lg font-semibold text-[#3456E6]" itemProp="name">
                                                    {selectedService.Title2}
                                                </h3>
                                                <p className="text-gray-600" itemProp="description">
                                                    {selectedService.Content2}
                                                </p>
                                            </div>
                                            <div className="bg-white p-4 rounded-lg shadow-lg">
                                                <h3 className="text-lg font-semibold text-[#3456E6]" itemProp="name">
                                                    {selectedService.Title3}
                                                </h3>
                                                <p className="text-gray-600" itemProp="description">
                                                    {selectedService.Content3}
                                                </p>
                                            </div>
                                            <div className="bg-white p-4 rounded-lg shadow-lg">
                                                <h3 className="text-lg font-semibold text-[#3456E6]" itemProp="name">
                                                    {selectedService.Title4}
                                                </h3>
                                                <p className="text-gray-600" itemProp="description">
                                                    {selectedService.Content4}
                                                </p>
                                            </div>
                                        </div>
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onClick={() => window.open("https://wa.me/573106014402?text=Hola,%20vengo%20por%20una%20consulta%20o%20me%20interesan%20sus%20servicios.", "_blank")}
                                            className="bg-[#3456E6] text-white text-lg font-semibold py-3 px-6 rounded-full hover:bg-blue-700 transition duration-300 self-start"
                                            rel="noopener noreferrer"
                                            aria-label={`Más información sobre ${selectedService.title}`}
                                        >
                                            Más información
                                        </motion.button>
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </section>
        </>
    );
}

export default Features;