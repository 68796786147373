import React from "react";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="bg-[#1E1E1E] text-gray-300 py-8" id="footer">
                <nav className="container mx-auto px-4 md:px-8 lg:px-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                    {/* ¿Qué puedo hacer por ti? */}
                    <section
                        className="space-y-4 text-center md:text-left"
                        aria-labelledby="services-header"
                    >
                        <motion.h4
                            id="services-header"
                            className="text-lg font-semibold text-white"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                        >
                            ¿Qué puedo hacer por ti?
                        </motion.h4>
                        <hr className="border-gray-700" />
                        <ul className="space-y-2">
                            {[
                                { text: 'Diseño y desarrollo', url: '#design' },
                                { text: 'Desarrollo de apps web', url: '#apps' },
                                { text: 'Software a la medida', url: '#custom-software' },
                                { text: 'Mantenimiento y soporte', url: '#support' },
                            ].map((item, index) => (
                                <motion.li
                                    key={index}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: { type: 'spring', stiffness: 300 },
                                    }}
                                >
                                    <a
                                        href={item.url}
                                        className="hover:text-white relative inline-block group"
                                        title={item.text}
                                        aria-label={item.text}
                                    >
                                        {item.text}
                                        <motion.span
                                            className="absolute left-0 bottom-0 h-[2px] w-0 bg-[#1d4ed8] group-hover:w-full transition-all"
                                            layoutId={`hover-underline-${index}`}
                                        ></motion.span>
                                    </a>
                                </motion.li>
                            ))}
                        </ul>
                    </section>

                    {/* Nosotros */}
                    <section
                        className="space-y-4 text-center md:text-left"
                        aria-labelledby="about-header"
                    >
                        <motion.h4
                            id="about-header"
                            className="text-lg font-semibold text-white"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.1 }}
                        >
                            Menu
                        </motion.h4>
                        <hr className="border-gray-700" />
                        <ul className="space-y-2">
                            {[
                                {
                                    text: 'Home',
                                    onClick: () => {
                                        const section = document.getElementById("hero-section");
                                        if (section) {
                                            section.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }
                                },
                                {
                                    text: 'Servicios',
                                    onClick: () => {
                                        const section = document.getElementById("que-podemos-hacer");
                                        if (section) {
                                            section.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }
                                },
                                {
                                    text: 'Procesos',
                                    onClick: () => {
                                        const section = document.getElementById("procesos");
                                        if (section) {
                                            section.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }
                                },
                                {
                                    text: 'Portafolio',
                                    onClick: () => {
                                        const section = document.getElementById("portafolio");
                                        if (section) {
                                            section.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }
                                }
                            ].map((item, index) => (
                                <motion.li
                                    key={index}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: { type: 'spring', stiffness: 300 },
                                    }}
                                >
                                    <button
                                        onClick={item.onClick}
                                        className="hover:text-white relative inline-block group text-white"
                                        title={item.text}
                                        aria-label={item.text}
                                    >
                                        {item.text}
                                        <motion.span
                                            className="absolute left-0 bottom-0 h-[2px] w-0 bg-[#1d4ed8] group-hover:w-full transition-all"
                                            layoutId={`hover-underline-${index + 4}`}
                                        ></motion.span>
                                    </button>
                                </motion.li>
                            ))}
                        </ul>
                    </section>


                    {/* ¿Trabajamos juntos? */}
                    <section
                        className="space-y-4 text-center md:text-left"
                        aria-labelledby="work-header"
                    >
                        <motion.h4
                            id="work-header"
                            className="text-lg font-semibold text-white"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
                        >
                            ¿Trabajamos juntos?
                        </motion.h4>
                        <hr className="border-gray-700" />
                        <ul className="space-y-2">
                            <li className="font-bold text-gray-300">¡Escríbenos!</li>
                            <li>
                                <a
                                    href="mailto:tubisnez@gmail.com"
                                    className="hover:text-white"
                                    title="Enviar un correo a TuBisnez"
                                    aria-label="Enviar un correo a TuBisnez"
                                >
                                    tubisnez@gmail.com
                                </a>
                            </li>
                        </ul>
                        <div
                            className="flex justify-center md:justify-start space-x-4 mt-4"
                            aria-label="Redes sociales"
                        >
                            {[
                                {
                                    platform: 'Instagram',
                                    url: 'https://www.instagram.com/tubisnez',
                                    iconClass: 'fab fa-instagram',
                                    color: 'hover:text-pink-500',
                                },
                                {
                                    platform: 'TikTok',
                                    url: 'https://www.tiktok.com/@tubisnez',
                                    iconClass: 'fab fa-tiktok',
                                    color: 'hover:text-blue-400',
                                },
                                {
                                    platform: 'Facebook',
                                    url: 'https://www.facebook.com/tubisnez',
                                    iconClass: 'fab fa-facebook',
                                    color: 'hover:text-blue-600',
                                },
                                {
                                    platform: 'YouTube',
                                    url: 'https://www.youtube.com/@tubisnez',
                                    iconClass: 'fab fa-youtube',
                                    color: 'hover:text-red-600',
                                },
                            ].map((social, index) => (
                                <a
                                    key={index}
                                    href={social.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`text-gray-400 ${social.color} transition`}
                                    title={`Visita nuestro ${social.platform}`}
                                    aria-label={`Visita nuestro ${social.platform}`}
                                >
                                    <i className={`${social.iconClass} text-xl`}></i>
                                </a>
                            ))}
                        </div>
                    </section>

                    {/* Enlaces */}
                    <section
                        className="space-y-4 text-center md:text-left"
                        aria-labelledby="links-header"
                    >
                        <motion.h4
                            id="links-header"
                            className="text-lg font-semibold text-white"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.3 }}
                        >
                            Enlaces
                        </motion.h4>
                        <hr className="border-gray-700" />
                        <ul className="space-y-2">
                            {[
                                { text: 'Terminos y Condiciones', url: '/terminosycondiciones' },
                                { text: 'Política de privacidad', url: '/politicasdeprivacidad' },
                            ].map((item, index) => (
                                <motion.li
                                    key={index}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: { type: 'spring', stiffness: 300 },
                                    }}
                                >
                                    <Link
                                        to={item.url} // Usa "to" en lugar de "href"
                                        className="hover:text-white relative inline-block group"
                                        title={item.text}
                                        aria-label={item.text}
                                    >
                                        {item.text}
                                        <motion.span
                                            className="absolute left-0 bottom-0 h-[2px] w-0 bg-[#1d4ed8] group-hover:w-full transition-all"
                                            layoutId={`hover-underline-${index + 8}`}
                                        ></motion.span>
                                    </Link>
                                </motion.li>
                            ))}
                        </ul>
                    </section>
                </nav>

                <div className="border-t border-gray-700 mt-8 pt-4 text-center">
                    <motion.p
                        className="text-gray-400 text-sm"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                        © {new Date().getFullYear()} <span className="text-[#1d4ed8] font-bold">TuBisnez</span> - Todos los derechos reservados
                    </motion.p>
                </div>
            </footer>
        </>
    );
}

export default Footer;