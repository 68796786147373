import React from "react";
import { Route, Routes } from "react-router-dom";
import Tubisnez from "./pages/tubisnez/TuBisnez";
import TermsAndConditions from "./pages/lib/termsAndConditions";
import PrivacyPolicy from "./pages/lib/privacyPolicy";

const AppRoutes = () => {
    return ( 
        <Routes>
            <Route path="/" element={<Tubisnez />} />
            <Route path="/terminosycondiciones" element={<TermsAndConditions />}/>
            <Route path="/politicasdeprivacidad" element={<PrivacyPolicy />}/>
        </Routes>
    );
};

export default AppRoutes;
