// src/components/loader.jsx
import React from 'react';
import logoLoader from '../utils/img/logoLoader.png';
const Loader = () => {
    return (
        <div className="loader-container">
            <div className="logo-animation">
                {/* Aquí puedes reemplazar src con la URL de tu logo */}
                <img src={logoLoader} alt="Tubisnez Logo" className="loader-logo" />
            </div>
        </div>
    );
};

export default Loader;
