import { motion } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";

export default function TimelineUI({ data }) {
  const containerRef = useRef(null);
  const headerRef = useRef(null);

  const [headerVisible, setIsHeaderVisible] = useState(false);
  const [visibleIndices, setVisibleIndices] = useState([]);

  useEffect(() => {
    if (typeof window === "undefined") return; // Asegura que estamos en el cliente

    const observerOptions = { root: null, threshold: 0.1 };

    // Observador para el header
    const headerObserver = new IntersectionObserver(([entry]) => {
      setIsHeaderVisible(entry.isIntersecting);
    }, observerOptions);

    if (headerRef.current) {
      headerObserver.observe(headerRef.current);
    }

    // Observador para entradas del timeline
    const timelineObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = Number(entry.target.getAttribute("data-index"));
          if (entry.isIntersecting) {
            setVisibleIndices((prev) => [...new Set([...prev, index])]);
          }
        });
      },
      observerOptions
    );

    const elements = document.querySelectorAll("[data-timeline-entry]");
    elements.forEach((el) => timelineObserver.observe(el));

    return () => {
      headerObserver.disconnect();
      timelineObserver.disconnect();
    };
  }, []);

  const renderHeader = () => (
    <header
      ref={headerRef}
      className="max-w-7xl mx-auto py-20 px-4 md:px-8 lg:px-10 text-center"
      id="procesos"
    >
      <motion.h2
        className="text-3xl md:text-5xl lg:text-5xl mb-4 text-[#3456E6] font-bold"
        initial={{ opacity: 0, filter: "blur(10px)" }}
        animate={{
          opacity: headerVisible ? 1 : 0,
          filter: headerVisible ? "blur(0px)" : "blur(10px)",
        }}
        transition={{ duration: 0.5 }}
      >
        Cómo Trabajamos para Garantizar tu Éxito
      </motion.h2>
      <motion.p
        className="text-center text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold my-4 mx-4 text-gray-700"
        initial={{ opacity: 0, filter: "blur(10px)" }}
        animate={{
          opacity: headerVisible ? 1 : 0,
          filter: headerVisible ? "blur(0px)" : "blur(10px)",
        }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        Nuestro enfoque está diseñado para ofrecerte soluciones claras y efectivas. Así es como trabajamos contigo.
      </motion.p>
    </header>
  );

  const renderTimelineEntries = () =>
    data.map((item, index) => {
      const isVisible = visibleIndices.includes(index);

      return (
        <div
          key={index}
          className="flex justify-start pb-8"
          data-timeline-entry
          data-index={index}
        >
          <div className="sticky flex flex-col md:flex-row z-20 items-center top-40 self-start max-w-xs lg:max-w-sm md:w-full">
            <div className="h-10 absolute left-3 md:left-3 w-10 rounded-full bg-white dark:bg-black flex items-center justify-center">
              <div className="h-4 w-4 rounded-full bg-neutral-200 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 p-2" />
            </div>
            <motion.h2
              className="hidden md:block text-xl md:pl-20 md:text-5xl font-bold text-neutral-500 dark:text-neutral-500"
              initial={{ opacity: 0, filter: "blur(10px)" }}
              animate={{
                opacity: isVisible ? 1 : 0,
                filter: isVisible ? "blur(0px)" : "blur(10px)",
              }}
              transition={{ duration: 0.5 }}
            >
              {item.title}
            </motion.h2>
          </div>
          <div className="relative pl-20 pr-4 md:pl-4 w-full">
            <motion.h2
              className="md:hidden block text-2xl mb-4 text-left font-bold text-neutral-500 dark:text-neutral-500"
              initial={{ opacity: 0, filter: "blur(10px)" }}
              animate={{
                opacity: isVisible ? 1 : 0,
                filter: isVisible ? "blur(0px)" : "blur(10px)",
              }}
              transition={{ duration: 0.5 }}
            >
              {item.title}
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, filter: "blur(10px)" }}
              animate={{
                opacity: isVisible ? 1 : 0,
                filter: isVisible ? "blur(0px)" : "blur(10px)",
              }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {item.content}
            </motion.div>
          </div>
        </div>
      );
    });

  return (
    <div className="w-full bg-white font-sans md:px-20" ref={containerRef}>
      {renderHeader()}
      <div className="relative max-w-7xl mx-auto">{renderTimelineEntries()}</div>
    </div>
  );
}
