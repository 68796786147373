import React from "react";
import TimelineUI from "../ui/processUI";

// Componente para mostrar una cuadrícula de imágenes
function ImageGrid({ images, altBase }) {
    return (
        <div className="grid grid-cols-2 gap-4">
            {images.map((img, i) => (
                <img
                    key={i}
                    src={require(`../../utils/img/${img}`)} // Ruta local
                    alt={`${altBase} - Imagen ${i + 1}`}
                    width={500}
                    height={500}
                    className="rounded-lg object-cover h-20 md:h-44 lg:h-60 w-full shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
                />
            ))}
        </div>
    );
}

// Componente principal de Timeline
export default function Timeline() {
    const data = [
        {
            title: <p className="text-[#3456E6]">Consulta Gratuita</p>,
            content: (
                <div className="py-2 md:py-8">
                    <p className="text-gray-700 font-semibold dark:text text-base md:text-lg mb-8">
                        Nos reunimos contigo para entender tus necesidades y objetivos digitales.
                    </p>
                    <ImageGrid
                        images={["cg1.webp", "cg2.webp"]}
                        altBase="Consulta gratuita"
                    />
                </div>
            ),
        },
        {
            title: <p className="text-[#3456E6]">Propuesta Personalizada</p>,
            content: (
                <div className="py-8">
                    <p className="text-gray-700 font-semibold dark:text text-base md:text-lg mb-8">
                        Creamos un plan claro con soluciones adaptadas y un presupuesto transparente.
                    </p>
                    <ImageGrid
                        images={["pp2.webp", "pp1.webp"]}
                        altBase="Propuesta personalizada"
                    />
                </div>
            ),
        },
        {
            title: <p className="text-[#3456E6]">Desarrollo del Proyecto</p>,
            content: (
                <div className="py-8">
                    <p className="text-gray-700 font-semibold dark:text text-base md:text-lg mb-8">
                        Trabajamos en el diseño y desarrollo, manteniéndote informado durante todo el proceso.
                    </p>
                    <ImageGrid
                        images={["ddp2.webp", "ddp1.webp"]}
                        altBase="Desarrollo del proyecto"
                    />
                </div>
            ),
        },
        {
            title: <p className="text-[#3456E6]">Entrega y Soporte Técnico</p>,
            content: (
                <div className="py-8">
                    <p className="text-gray-700 font-semibold dark:text text-base md:text-lg mb-8">
                        Te entregamos una solución lista para usar, con soporte continuo para garantizar su éxito.
                    </p>
                    <ImageGrid
                        images={["eyst1.webp", "eyst2.webp"]}
                        altBase="Entrega y soporte técnico"
                    />
                </div>
            ),
        },
    ];

    return (
        <>
            <TimelineUI data={data} />
            {/* JSON-LD para datos estructurados */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "HowTo",
                    "name": "Cómo trabajamos",
                    "description": "Nuestro proceso en cuatro pasos: consulta gratuita, propuesta personalizada, desarrollo del proyecto, y soporte técnico.",
                    "step": data.map((entry, index) => {
                        const title = React.isValidElement(entry.title)
                            ? entry.title.props.children
                            : entry.title || `Paso ${index + 1}`;
                        const text = React.isValidElement(entry.content)
                            ? entry.content.props.children?.[0]?.props?.children || "Descripción no disponible"
                            : "Descripción no disponible";
                        const images = React.isValidElement(entry.content)
                            ? entry.content.props.children?.[1]?.props?.images || []
                            : [];
                        const image = images.length > 0 ? `/img/${images[0]}` : undefined;

                        return {
                            "@type": "HowToStep",
                            "position": index + 1,
                            "name": title,
                            "text": text,
                            ...(image ? { image } : {}),
                        };
                    }),
                })}
            </script>
        </>
    );
}
