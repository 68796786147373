"use client";
import { ArrowLeftCircleFill, ArrowRightCircleFill, StarFill } from "react-bootstrap-icons";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import distTest from "../../utils/img/distrimarktes.webp";
import cirsTest from "../../utils/img/crisahtes.webp";

const testimonials = [
  {
    id: 1,
    quote:
      "Trabajar con ellos es una gran experiencia, tanto personal como Profesional, son personas capaces y muy atentas a todas tus necesidades como emprendedor. Han sido unos excelentes aliados en nuestro desarrollo como empresa 👍🏼",
    name: "Cristhian Burbano",
    designation: "CEO de CRISHA CAPS",
    src: cirsTest,
  },
  {
    id: 2,
    quote:
      "Crearon una pagina web perfecta para nuestro negocio. El proceso fue sencillo, me hicieron participe del mismo y el resultado superó todas mis expectativas. 🚢👏🏼",
    name: "Javier Burbano",
    designation: "CEO de DISTRIMARK PLUS",
    src: distTest,
  },
];

function TestimonialsUi() {
  const randomRotateY = () => Math.floor(Math.random() * 21) - 10;
  const [active, setActive] = useState(0);

  const isActive = (index) => index === active;
  const handlePrev = () => setActive((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  const handleNext = () => setActive((prev) => (prev + 1) % testimonials.length);

  useEffect(() => {
    const interval = setInterval(handleNext, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8 z-10 items-center text-center md:text-left">
        {/* Imagen con animación */}
        <div className="relative h-64 sm:h-80 w-full flex justify-center items-center">
          <AnimatePresence>
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={testimonial.id}
                initial={{
                  opacity: 0,
                  scale: 0.9,
                  z: -100,
                  rotate: randomRotateY(),
                }}
                animate={{
                  opacity: isActive(index) ? 1 : 0.7,
                  scale: isActive(index) ? 1 : 0.95,
                  z: isActive(index) ? 0 : -100,
                  rotate: isActive(index) ? 0 : randomRotateY(),
                  zIndex: isActive(index) ? 999 : testimonials.length + 2 - index,
                }}
                exit={{
                  opacity: 0,
                  scale: 0.9,
                  z: 100,
                  rotate: randomRotateY(),
                }}
                transition={{
                  duration: 0.4,
                  ease: "easeInOut",
                }}
                className="absolute inset-0"
              >
                <img
                  src={testimonial.src}
                  alt={testimonial.name}
                  width={500}
                  height={500}
                  draggable={false}
                  className="w-full h-full sm:max-w-sm sm:max-h-sm md:max-w-md md:max-h-md lg:max-w-lg lg:max-h-lg object-cover rounded-3xl"
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        {/* Texto y botones */}
        <div className="flex flex-col justify-between items-center md:items-start py-4">
          <motion.div
            key={active}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <h3 className="text-xl sm:text-2xl font-bold text-white">
              {testimonials[active].name}
            </h3>
            <p className="text-sm text-gray-500 dark:text-neutral-500">
              {testimonials[active].designation}
            </p>
            <motion.p className="text-sm sm:text-lg text-gray-500 mt-4 dark:text-neutral-300 italic">
              "{testimonials[active].quote}"
            </motion.p>
            <div className="flex mt-4 justify-center md:justify-start">
              {[...Array(6)].map((_, index) => (
                <StarFill key={index} className="text-yellow-500" />
              ))}
            </div>
          </motion.div>
          <div className="flex gap-4 pt-8 justify-center md:justify-start">
            <button
              onClick={handlePrev}
              className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-gray-100 dark:bg-neutral-800 flex items-center justify-center"
            >
              <ArrowLeftCircleFill className="h-5 w-5 text-black dark:text-neutral-400" />
            </button>
            <button
              onClick={handleNext}
              className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-gray-100 dark:bg-neutral-800 flex items-center justify-center"
            >
              <ArrowRightCircleFill className="h-5 w-5 text-black dark:text-neutral-400" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestimonialsUi;
