import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export function Counter() {
    const [time, setTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    // Agregar JSON-LD
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "Offer",
        "name": "Descuento del 20% en diseño web",
        "description": "Aprovecha un 20% de descuento en el diseño de tu sitio web o consulta gratuita para tu primer proyecto.",
        "url": "https://wa.me/+573150033359?text=¡Hola!%20Quiero%20mi%20cotización%20gratis.",
        "price": "285,68",
        "priceCurrency": "USD",
        "availability": "https://schema.org/InStock",
        "validThrough": "2024-12-01T23:59:59",
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const targetDate = new Date("2025-01-01T00:00:00");
            const currentDate = new Date();

            const timeDifference = targetDate.getTime() - currentDate.getTime();

            if (timeDifference <= 0) {
                setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                clearInterval(interval);
                return;
            }

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setTime({ days, hours, minutes, seconds });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div lang="es" className="flex flex-col items-center">
                <motion.h2
                    className="text-[#3456E6] text-3xl text-center sm:text-left md:text-5xl lg:text-5xl font-bold my-4"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                >
                    ¡Oferta de Lanzamiento!
                </motion.h2>
                <motion.div
                    className="bg-[#3456E6] w-[90%] sm:w-[80%] lg:w-[70%] xl:w-[60%] rounded-2xl p-6 md:p-8 flex flex-col sm:flex-row justify-center items-center gap-6"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <div className="flex justify-around w-full">
                        {["days", "hours", "minutes", "seconds"].map((key, index) => (
                            <motion.div
                                key={key}
                                className="flex flex-col items-center text-white"
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                    duration: 0.6,
                                    ease: "easeOut",
                                    delay: index * 0.2, // Retraso en cascada para cada elemento
                                }}
                            >
                                <span className="text-lg sm:text-xl md:text-2xl">
                                    {["Días", "Horas", "Minutos", "Segundos"][index]}
                                </span>
                                <span
                                    className="text-2xl sm:text-4xl md:text-5xl font-bold"
                                    aria-label={`Faltan ${time[key]} ${["días", "horas", "minutos", "segundos"][index]
                                        }`}
                                >
                                    {time[key]}
                                </span>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
                <motion.h2
                    className="text-center text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold my-4 mx-4 text-gray-700"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut", delay: 0.8 }}
                >
                    Aprovecha un 20% de descuento en el diseño de tu sitio web o consulta gratuita para tu primer proyecto.
                </motion.h2>
                <motion.div
                    className="flex justify-center mt-4"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6, ease: "easeOut", delay: 1 }}
                >
                    <motion.button
                        aria-label="Solicitar oferta de lanzamiento con un 20% de descuento"
                        title="Solicita tu oferta ahora"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => window.open("https://wa.me/573106014402?text=Hola,%20me%20interesa%20obtener%20la%20%20oferta.", "_blank")}
                        className="bg-[#3456E6] hover:bg-[#2c4fe9] text-white text-sm sm:text-lg md:text-xl font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-all duration-300"
                    >
                        ¡Solicita tu Oferta Ahora!
                    </motion.button>
                </motion.div>
            </div>
            <script type="application/ld+json">
                {JSON.stringify(jsonLd)}
            </script>
        </>
    );
}

export default Counter;
