import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import logo from '../../utils/img/logo.webp';
import { Link } from "react-router-dom";

const NavbarMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <>
            <motion.nav
                className="fixed top-3 left-1/2 transform -translate-x-1/2 z-40 text-white"
                initial={{
                    backgroundColor: "transparent",
                    width: "100%",
                    borderRadius: "0px",
                }}
                animate={{
                    backgroundColor: isScrolled ? "#1d4ed8" : "transparent",
                    width: isScrolled ? "70%" : "100%",
                    borderRadius: isScrolled ? "50px" : "50px",
                }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16 items-center">
                        {/* Logo */}
                        <div className="flex items-center">
                            <img
                                src={logo}
                                alt="Logo"
                                width={40}
                                height={40}
                                className="mr-2"
                            />
                            <Link to="/" className="text-xl font-bold">
                                TuBisnez
                            </Link>
                        </div>

                        {/* Desktop Menu */}
                        <div className="hidden md:flex space-x-4 items-center">
                            <motion.button
                                onClick={() => {
                                    const section = document.getElementById("hero-section");
                                    if (section) {
                                        section.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                                className="px-3 py-2 rounded-md text-sm font-bold bg-transparent border-none text-white cursor-pointer"
                                whileHover={{
                                    scale: 1.2,
                                }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                Home
                            </motion.button>


                            <motion.button
                                onClick={() => {
                                    const section = document.getElementById("que-podemos-hacer");
                                    if (section) {
                                        section.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                                className="px-3 py-2 rounded-md text-sm font-bold cursor-pointer"
                                whileHover={{
                                    scale: 1.2,
                                }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                Servicios
                            </motion.button>

                            <motion.button
                                onClick={() => {
                                    const section = document.getElementById("procesos");
                                    if (section) {
                                        section.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                                className="px-3 py-2 rounded-md text-sm font-bold cursor-pointer"
                                whileHover={{
                                    scale: 1.2,
                                }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                Procesos
                            </motion.button>

                            <motion.button
                                onClick={() => {
                                    const section = document.getElementById("portafolio");
                                    if (section) {
                                        section.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                                className="px-3 py-2 rounded-md text-sm font-bold cursor-pointer"
                                whileHover={{
                                    scale: 1.2,
                                }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                Portafolio

                            </motion.button>

                            <motion.a
                                href="https://wa.me/573106014402?text=Hola,%20vengo%20por%20una%20consulta%20o%20me%20interesan%20sus%20servicios."
                                target="_blank"
                                rel="noopener noreferrer"
                                className="border rounded-full border-white px-4 py-2 text-sm font-bold hover:bg-white hover:text-gray-800 transition"
                                whileHover={{
                                    scale: 1.2, // Mayor escalado para el botón destacado
                                    backgroundColor: "#ffffff",
                                    color: "#1d4ed8",
                                }}
                                transition={{ duration: 0.1, ease: "easeInOut" }}
                            >
                                Contacto
                            </motion.a>

                        </div>
                        {/* Mobile Menu Button */}
                        <div className="flex md:hidden">
                            <button
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                className="border border-white p-2 rounded-md text-white-300 hover:bg-white hover:text-gray-800 focus:outline-none"
                                aria-label="Toggle navigation"
                            >
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </motion.nav>

            {/* Off-canvas Mobile Menu */}
            <motion.div
                className="fixed inset-0 z-40 bg-black bg-opacity-50"
                initial={{ opacity: 0 }}
                animate={{ opacity: isMenuOpen ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                onClick={() => setIsMenuOpen(false)} // Close when clicking outside
                style={{ pointerEvents: isMenuOpen ? "auto" : "none" }}
            >
                <motion.div
                    className="fixed top-0 left-0 h-full w-3/4 bg-blue-800 text-white shadow-lg"
                    initial={{ x: "-100%" }}
                    animate={{ x: isMenuOpen ? "0%" : "-100%" }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="flex justify-between items-center p-4 border-b border-blue-600 z-50">
                        <h2 className="text-lg font-semibold">Menu</h2>
                        <button
                            className="text-white hover:text-gray-300"
                            onClick={() => setIsMenuOpen(false)}
                            aria-label="Close menu"
                        >
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <nav className="mt-4">
                        <button onClick={() => {
                            const section = document.getElementById("hero-section");
                            if (section) {
                                section.scrollIntoView({ behavior: "smooth" });
                            }
                        }} className="block px-4 py-2 hover:bg-blue-600">
                            Home
                        </button>
                        <button onClick={() => {
                            const section = document.getElementById("que-podemos-hacer");
                            if (section) {
                                section.scrollIntoView({ behavior: "smooth" });
                            }
                        }} className="block px-4 py-2 hover:bg-blue-600">
                            Servicios
                        </button>
                        <button onClick={() => {
                            const section = document.getElementById("procesos");
                            if (section) {
                                section.scrollIntoView({ behavior: "smooth" });
                            }
                        }} className="block px-4 py-2 hover:bg-blue-600">

                            Procesos
                        </button>
                        <button onClick={() => {
                            const section = document.getElementById("portafolio");
                            if (section) {
                                section.scrollIntoView({ behavior: "smooth" });
                            }
                        }} className="block px-4 py-2 hover:bg-blue-600">
                            Portafolio
                        </button>
                        <button href="/contact" className="block px-4 py-2 hover:bg-blue-600">
                            Contacto
                        </button>
                    </nav>
                </motion.div>
            </motion.div>
        </>
    );
}

export default NavbarMenu;