import React, { useState, useEffect } from "react";
import '../utils/styles.css';
import NavbarMenu from "./componentes/navbarMenu";
import HeroSection from "./componentes/heroSection";
import Features from "./componentes/features";
import Counter from "./componentes/counter";
import Timeline from "./componentes/process";
import Testimonials from "./componentes/testimonials";
import PortfolioMarquee from "./componentes/portfolio";
import ContactUs from "./componentes/contactUs";
import Footer from "./componentes/footer";
import Loader from "../lib/loader";

const Tubisnez = () => {
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setIsloading(false), 3000); // Simulacion de tiempo de carga 3seg
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <NavbarMenu />
                    <HeroSection />
                    <Features />
                    <Counter />
                    <Timeline />
                    <Testimonials />
                    <PortfolioMarquee />
                    <ContactUs />
                    <Footer />
                </>
            )}
        </>
    );
}

export default Tubisnez;