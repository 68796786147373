import React from "react";

const TermsAndConditions = () => {
    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl">
                <h1 className="text-3xl font-bold mb-4 text-gray-800">Términos y Condiciones</h1>
                <p className="text-gray-600 mb-6">
                    Bienvenido a nuestra página web. Al acceder a este sitio, aceptas cumplir con los siguientes términos y condiciones de uso. Si no estás de acuerdo con alguno de estos términos, por favor no utilices nuestro sitio.
                </p>
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Uso del Sitio</h2>
                <p className="text-gray-600 mb-4">
                    Este sitio es solo para fines informativos. No recolectamos información personal ni almacenamos datos de los usuarios que navegan por el sitio.
                </p>
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Enlaces a Terceros</h2>
                <p className="text-gray-600 mb-4">
                    Este sitio puede contener enlaces a sitios web de terceros. No somos responsables por el contenido o la precisión de esos sitios.
                </p>
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Propiedad Intelectual</h2>
                <p className="text-gray-600 mb-4">
                    Todo el contenido de este sitio, incluidos textos, imágenes y gráficos, es propiedad de la empresa y está protegido por derechos de autor.
                </p>
                <p className="text-gray-600">
                    Si tienes preguntas sobre estos términos, por favor contáctanos a través de nuestro correo electrónico o WhatsApp.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;