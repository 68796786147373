import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl">
                <h1 className="text-3xl font-bold mb-4 text-gray-800">Política de Privacidad</h1>
                <p className="text-gray-600 mb-6">
                    En nuestra página web, respetamos tu privacidad. Este documento explica cómo manejamos la información que nos proporcionas al utilizar nuestro sitio.
                </p>
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Datos Recolectados</h2>
                <p className="text-gray-600 mb-4">
                    No recolectamos datos personales de los usuarios que navegan por nuestro sitio. Las interacciones se limitan a los botones de contacto directo a WhatsApp y correo electrónico.
                </p>
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Cookies</h2>
                <p className="text-gray-600 mb-4">
                    Este sitio no utiliza cookies ni otras tecnologías de seguimiento para recopilar información sobre los visitantes.
                </p>
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Enlaces Externos</h2>
                <p className="text-gray-600 mb-4">
                    Nuestro sitio puede contener enlaces a plataformas externas como WhatsApp o correo electrónico. No somos responsables por las prácticas de privacidad de esos servicios.
                </p>
                <p className="text-gray-600">
                    Si tienes preguntas sobre nuestra política de privacidad, no dudes en contactarnos.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;