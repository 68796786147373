import React from "react";
import { motion } from 'framer-motion';
import video from '../../utils/video/video.mp4';
import poster from '../../utils/img/video.webp';
import { FlipWords } from "../ui/flipWords";

const HeroSection = () => {
    const words = ["efectivas", "innovadoras", "creativas", "modernas"];

    const scrollToQuePodemosHacer = () => {
        const section = document.getElementById("que-podemos-hacer");
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <section id="hero-section" className="relative hero" aria-labelledby="hero-title">
                <video
                    src={video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster={poster}
                    className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
                    aria-hidden="true"
                >
                    Tu navegador no soporta el video. Por favor, actualizalo.
                </video>
                <div className="flex flex-col justify-center items-center h-full px-4 relative z-10 sm:justify-start sm:items-start">
                    {/* Título principal */}
                    <div
                        id="hero-title"
                        className="text-4xl sm:text-5xl md:text-6xl font-bold text-white text-center sm:text-left mt-0 sm:mt-24 sm:ml-8 md:mt-40 md:ml-12"
                    >
                        <h1>
                            Transformamos ideas <br />
                            en soluciones <br />
                            <span className="text-4xl sm:text-5xl md:text-6xl ml-0">
                                <FlipWords words={words} />
                            </span>
                        </h1>
                    </div>

                    {/* Subtítulo */}
                    <div className="text-xl sm:text-2xl font-normal text-white text-center sm:text-left mt-4 sm:ml-8 md:ml-14">
                        <h2>Diseñamos y desarrollamos <br /> experiencias web únicas e innovadoras.</h2>
                        <h3 className="italic text-lg font-normal">
                            Lleva tu negocio al siguiente nivel en toda Colombia.
                        </h3>
                    </div>

                    {/* Botón de acción */}
                    <div className="mt-6 ml-4 sm:ml-8 md:ml-14">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={scrollToQuePodemosHacer}
                            className="px-6 py-3 bg-white text-[#3456E6] rounded-full shadow hover:shadow-md transition-all duration-300 font-bold"
                            aria-label="Haz tu cotización gratuita"
                        >
                            Que podemos hacer?
                        </motion.button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroSection;