"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import TestimonialsUi from "../ui/animatedTestimonials";

const Testimonials = () => {
    const [inView, setInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById("testimonials-container");
            if (element) {
                const rect = element.getBoundingClientRect();
                if (rect.top < window.innerHeight * 0.8) {
                    setInView(true);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Para verificar la visibilidad en la carga inicial

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <motion.div
            id="testimonials-container"
            initial={{ y: 100, opacity: 0 }}
            animate={inView ? { y: 0, opacity: 1 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
            className="main-container"
        >
            {/* SVG de transición */}
            <svg
                aria-hidden="true"
                viewBox="0 -1 1440 230"
                className="wave-transition"
                style={{ marginTop: "-15px", width: "100%" }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="#1E1E1E"
                    fillOpacity="1"
                    d="M0,192L120,170.7C240,149,480,107,720,112C960,117,1200,171,1320,197.3L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                ></path>
            </svg>

            {/* Contenedor de testimonios */}
            <section aria-labelledby="testimonials-title" className="bg-[#1E1E1E] w-full">
                <h4 className="text-3xl md:text-5xl lg:text-5xl font-bold text-center text-white" id="testimonials-title">
                    Lo Que Dicen Nuestros Clientes
                </h4>
                <p className="font-semibold text-center mt-4 text-sm md:text-lg mx-4 text-white">
                    Nuestros clientes confían en nosotros para llevar sus negocios al mundo digital. Aquí tienes algunas historias de éxito
                </p>
                <div className="max-w-lg md:max-w-4xl mx-auto px-4 md:px-8 lg:px-12 py-8 md:py-20">
                    <TestimonialsUi />
                </div>
            </section>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "ItemList",
                    "name": "Testimonios de Clientes sobre Diseño y Desarrollo Web",
                    "itemListElement": [
                        {
                            "@type": "Review",
                            "author": "Juan Pérez",
                            "datePublished": "2023-10-15",
                            "reviewBody": "El servicio de diseño web fue increíble, mi negocio ha crecido significativamente.",
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5",
                                "bestRating": "5"
                            }
                        },
                        {
                            "@type": "Review",
                            "author": "María López",
                            "datePublished": "2023-09-30",
                            "reviewBody": "Gran equipo de desarrollo, muy profesionales y atentos.",
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5",
                                "bestRating": "5"
                            }
                        }
                    ]
                })}
            </script>
        </motion.div>
    );
};

export default Testimonials;
